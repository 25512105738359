@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
html, body {
    font-family: Roboto,"Helvetica Neue",sans-serif !important;
}
h4, h5, h6 {
    margin-top: 6px;
    margin-bottom: 6px;
}
form {
    margin-top: 5px;
}
.ScrollbarContainer--scrolling {
    -webkit-touch-callout: none;
    user-select: none;
  }
  
  .ScrollbarContainer>div::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  
  .ScrollbarContainer>div::scrollbar {
    width: 0;
    height: 0;
  }
.k-textbox-container {
    width: 100% !important;
    margin-bottom: 6px;
}
.k-textbox-container.k-state-empty>.k-label {
    color: #bbb;
}
.k-multiselect .k-multiselect-wrap, .k-numerictextbox .k-numeric-wrap {
    border: none;
}
.k-textbox-container>.k-label, .k-textbox-container.k-state-focused>.k-label {
    -webkit-transform: translate(-.75rem, -.28125rem) translate(-1px, -1.125em) translate(0%, -25%) scale(.85);
    -ms-transform: translate(-.75rem, -.28125rem) translate(-1px, -1.125em) translate(0%, -25%) scale(.85);
    transform: translate(-.75rem, -.28125rem) translate(-1px, -1.125em) translate(0%, -25%) scale(.85);
    left: 8px;
    top: calc(1.03125em + 9px);
}
.k-textbox-container:not(.k-state-empty)>.k-autocomplete+.k-label,
.k-textbox-container:not(.k-state-empty)>.k-combobox+.k-label,
.k-textbox-container:not(.k-state-empty)>.k-dropdown+.k-label {
    left: 11px;
}
.k-dialog-buttongroup button {
    min-width: 100px;
}
.k-animation-container {
    z-index: 10003;
}
.clickableRow .k-master-row {
    cursor: pointer;
}
.k-dropdown .k-dropdown-wrap {
    border: none;
}
.k-textbox, .k-textarea, .k-input.k-textbox, .k-autocomplete, .k-dateinput, .k-select, .k-combobox, .k-dropdown, .k-multiselect, .k-numerictextbox {
    border-width: 1px;
    padding-left: 8px;
    padding-right: 8px;
    border-color: rgba(0,0,0,0.2);
    transition: border-color 300ms;
}
.k-multiselect-wrap .k-select {
    padding-left: 0;
    padding-right: 0;
}
.k-datepicker .k-picker-wrap .k-select, .k-datetimepicker .k-picker-wrap .k-select {
    border-width: 1px 1px 1px 0;
    opacity: 1;
}
.k-combobox .k-dropdown-wrap .k-select, .k-dropdown .k-dropdown-wrap .k-select {
    margin-right: -8px;
}
.k-select .k-icon {
    opacity: .54;
}
.k-datepicker .k-picker-wrap, .k-datetimepicker .k-picker-wrap, .k-combobox .k-dropdown-wrap, .k-dropdown .k-dropdown-wrap {
    border-width: 0;
}
.k-state-invalid .k-widget, .k-state-invalid .k-select  {
    border-color: #f31700;
}
.k-textbox-container::after {
    display: none;
} 

.k-dateinput .k-dateinput-wrap .k-input[value='dd/MM/yyyy'] {
    color: #777;
}

.k-textbox:hover, .k-textbox.k-state-hover, 
.k-textarea:hover, .k-textarea.k-state-hover, 
.k-input.k-textbox:hover, .k-input.k-textbox.k-state-hover,
.k-combobox:hover, .k-combobox.k-state-hover, 
.k-dropdown:hover, .k-dropdown.k-state-hover,
.k-datepicker:hover, .k-datepicker.k-state-hover, 
.k-datetimepicker:hover, .k-datetimepicker.k-state-hover, 
.k-autocomplete:hover, .k-autocomplete.k-state-hover,
.k-multiselect:hover, .k-multiselect.k-state-hover
{
    border-color: rgba(0,0,0,0.4);
}
.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap, .k-multiselect {
    border-width: 1px;
}

.k-pager-wrap .k-pager-nav, .k-pager-wrap .k-pager-numbers .k-link, .k-pager-wrap .k-pager-numbers .k-state-selected, .k-pager-wrap>.k-link {
    border-radius: 2px;
}

.k-grid-content tr:last-child td, .k-grid-content-locked tr:last-child td {
    border-bottom-width: 1px;
    margin-bottom: 5px;
}
.k-pager-numbers .k-link {
    transition: all .2s ease-in-out;
}
.k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover {
    border-color: #ab47bc;
    color: #fff;
    background-color: #ab47bc;
}
.k-tabstrip>.k-content {
    padding: 14px 0px;
}
.divtext {
    font-size: 14px;
    padding: 5px;
    width: 100%;
    height: 4.6rem;
    overflow: auto;
    white-space: pre;
}
.divtext:empty:before {
    content: "Type here...";
    color: #999;
    font-style: italic;
}
.date-card-wrapper {
    display: inline-block !important;
    margin-right: 15px;
    margin-top: 0px !important;
}
.date-card {
    color: #555;
}
.date-card>div {
    padding: .295rem 1.082rem !important;
    color: #fff;
}
.date-card>div * {
    text-align: center;
}
.date-card p {
    font-size: 12px;
    margin: 0;
    padding: 0;
}
.date-card>div>h6 {
    font-size: 16px;   
    margin: 0;
    padding: 0;
}
.related-card-wrapper {
    width: calc(100% - 5px);
    margin-bottom: 12px;
    height: calc(100% - 12px);
}

.related-card-wrapper .related-card-header {
    display: flex;
    justify-content: space-between;
}
.related-card-wrapper .related-card-body {
    padding-bottom: 0;
}
.related-card-wrapper .related-card-body .MuiGrid-item {
    margin-bottom: 5px;
}
.related-title {
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding-bottom: 6px;
    margin: 16px 0 12px 0;
}


.jm-grid-noheader .k-grid-header {
    display: none;
}

.k-widget.k-state-invalid>.k-dropdown-wrap {
    border-color: #f31700;
}
.k-widget.k-state-invalid.k-dropdown>.k-dropdown-wrap {
    border: none;
}
.k-dropdown .k-dropdown-wrap .k-input {
    padding: 8px;
}

.k-tabstrip-items .k-link {
    padding: 10px 32px;
}
.k-grid-toolbar {
    padding: 10px;
}
.k-grid th {
    padding: 8px 12px;
}
.k-grid td {
    padding: 6px 12px;
}
.jm-card-title {
    display: inline-block;
}
.jm-action-btn{
    display: inline-block;
}
.jm-action-btn.jm-header-btn  {
    margin-left: 15px;
    margin-top: -8px;  
}
.k-upload {
    margin-top: 12px;
}
.k-button {
    font-size: 8px;    
}
.k-button.k-title, .profile .k-button {
    font-size: 12px;    
}

.k-grid .k-button, .k-grid-toolbar .k-button {
    font-size: 8px; 

}
.k-window .k-button{
    font-size: 12px;    
}
.jm-form-section-title h5 {
    display: inline-block;
}
.jm-form-section-title~.k-grid{
    margin-top: 12px;
}
.jm-form-section-title .k-button {
    font-size: 8px; 
    margin-left: 15px;
}
.k-textbox, .k-textarea, .k-input {
    font-size: 14px !important;
}
.k-button:not(.k-window-action) .k-icon, .k-grid-pager {
    font-size: 12px;
}
.k-textbox-container>.k-label {
    font-size: 14px;
}
.k-textbox-container>.k-label, .k-textbox-container.k-state-focused>.k-label {
    top: calc(1.03125em + 6px);
}
.k-pager-wrap .k-link, .k-pager-wrap .k-state-selected {
    min-width: calc(16px + 0.952em);
    height: calc(16px + 0.952em);
}
.jm-action-btn.k-button-icon{
    padding: 4px;
    width: calc(16px + 1.4285714286em);
    height: calc(16px + 1.4285714286em);
}
/* .k-grid {
    height: 24rem;
} */

.k-tabstrip-items .k-item, .k-pager-info, .k-pager-sizes {
    font-size: 12px;
}
.k-pager-sizes .k-dropdown .k-dropdown-wrap .k-select {
    padding: 0 4px;
    font-size: 8px;
}
.k-pager-sizes .k-dropdown .k-dropdown-wrap .k-input {
    height: calc(16px + .65em);
}
.k-grid-pager {
    padding: 6px 10px;
}
.k-widget {
    font-size: 12px;
}
.k-tabstrip-top.jm-no-underline>.k-tabstrip-items .k-item::after {
    border-bottom-width: 0;
}
.k-tabstrip-top.jm-no-underline>.k-tabstrip-items li  {
    width: 100%;
}
.k-tabstrip-top.jm-no-underline>.k-tabstrip-items li span.k-link>span  {
    width: 100%;
    display: flex;
}
.k-tabstrip-top.jm-no-underline .k-animation-container  {
    width: 100%;
}
.k-tabstrip-top.jm-detail-tab .k-content:not(.k-window-content){
    padding: 0px;
}
.k-detail-row .k-hierarchy-cell {
    display: none;
}
.k-detail-row .k-detail-cell {
    padding: 2px 8px 10px 8px;
}
.popup-content {
    padding: 5px;
    color: #787878;
    background-color: #fcf7f8;
    border: 1px solid rgba(0,0,0,.05);
    min-width: 300px;
}

hr {
    margin-top: 10px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.12);
}

.k-button span {
    font-size: 12px;
    text-transform: none;
}

.k-checkbox:hover {
    border-color: rgba(0, 0, 0, 0.54);
}
.k-checkbox {
    border-color: rgba(0,0,0,0.12);
    border-width:1px;
}
.jm-action-btn:disabled {
    background-color: #3f51b5 !important;
    color: #ffffff !important;
}
.k-grid.hide-header .k-grid-header {
    display: none;
}

.k-dialog-actions .k-button span {
    font-size: 16px !important;
}
.highlight-text, .highlight {
    background-color: yellow;
    padding: 0;
}

.searchbox {
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    height: 42px;
}
@media (max-width: 1280px){
    .searchbox {
        width: 100%;
    }
}
.searchbox .k-textbox {
    border: none;
    width:100%;
    padding: 21px 15px;
    font-size: 16px !important;
}
.searchbox .k-button {
    box-shadow: none;
    height: 32px;
    width: 34.55px;
    border-radius: 50%;
    margin: 5px;
}
.searchbox .k-button span {
    font-size: 20px !important;
} 
.jm-outer-btn>span:first-child {
    background-color: #3f51b5;
    color: #ffffff;
    display: flex;
    height: 27.42px;
    width: 27.42px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
}
.k-split-button .k-button {
    border-color: #3f51b5;
    color: #fff;
    background-color: #3f51b5;
}
.k-detail-cell {
    padding: 0 !important;
}
.k-detail-cell, .k-detail-cell .k-grid {
    border: none;
}
.k-detail-cell .k-grid-header {
    display: none;
}
.incellEdit:hover {
    color: #1992ce;
}

.k-detail-cell .k-grid-content tr:last-child td, .k-detail-cell .k-grid-content-locked tr:last-child td {
    border-bottom-width: 0;
}

.k-dialog .k-dialog-content {
    max-height: calc(100vh - 200px);
}
.k-checkbox {
    padding: 10px !important;
}
.k-checkbox:checked::before {
    max-width: 18px;
}
.k-checkbox::before {
    width: 18px;
    height: 18px;
    font-size: 18px;
}

.list-view-item {
    font-size: 12px;
    padding: 5px 0px 5px 10px; 
    cursor: pointer;    
    transition: all ease-in 300ms;
    /* margin-right: 10px; */
}
.list-view-item:not(:last-child) {
    /* border-bottom: 1px solid rgba(0,0,0,0.12);    */
}
.list-view-item:hover {
    background-color: rgba(0,0,0,0.07);
}
.list-view-buttons {
    float: right;
    box-shadow: 0 1px 1px -2px rgba(0,0,0,0.12), 0 1px 2px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.12);
}
.list-view-buttons .k-button {
    font-size: 10px;
}
.list-view-item-container, .list-view-item-container-empty {
    height: calc(100% - 40px);
    border: 1px solid rgba(0,0,0,0.12);
}

.list-view-item-container {
    overflow: hidden auto;
    

}
.list-view-item-container-empty {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0,0,0,0.62);
}
.list-view-footer {
    padding: 4px 16px 4px 0;
    border: 1px solid rgba(0,0,0,0.12);
    border-top: none;
    margin-bottom: 5px;
}
.list-view-footer-link {
    float: right;
    margin-top: 5px;
}
.event-item {
    display: flex;
    width: 240px;
    padding: 10px;
}

.event-item .event-item-title {
    border: 1px solid rgba(0,0,0,0.12);
    width: 54px;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.event-item .event-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 8px;
}
.event-item .event-item-title *, .event-item .event-item-content * {
    padding: 0;
    margin: 0;
}
.event-item .event-item-title * {
    text-transform: capitalize;
}
.event-item .event-item-content * {
    text-transform: none;
}

div.event-item:hover, div.date-card-wrapper:hover, p.document-item:hover {
    background-color: rgba(0,0,0,0.07);
    cursor: pointer;
}

.k-link:hover {
    /* text-decoration: underline; */
    color: blue;
}
.k-link {
    /* text-decoration: underline; */
    color: royalblue;
}
.k-window-title {
    align-items: center;
}

.one-line-html {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.one-line-html * {
    display: inline;
    margin-right: 6px;    
}
.font-size-14 {
    font-size: 14px;
}
.k-textbox-container:not(.k-state-empty)>.k-label, .k-textbox-container.k-state-focused>.k-label, .k-checkbox-label {
    font-size: 13px;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    left: 11px;
    /* transform: translate(-.75rem, -.28125rem) translate(-1px, -1.4em) translate(0%, -25%) scale(.95); */
    transform: translate(-.65rem, -1.8rem);
}
.k-checkbox-label {
    transform: scale(.95);
    left: 2px;
}
.k-textbox-container:not(.k-state-empty)>.k-label::after, .k-textbox-container.k-state-focused>.k-label::after {
    content: ":";
}
.grid-with-footer{
    height: calc(100% - 41px);
    border-bottom-width: 0;
}
.grid-footer {
    border-color: rgba(0,0,0,0.12);
    color: rgba(0,0,0,0.87);
    background-color: #fff;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    height: 41px;
    padding: 6px 16px 6px 0

}
.k-master-row:last-child, .k-grid.no-pager {
    border-bottom: none;
}
.grid-scroll-wrapper {
    border-color: rgba(0,0,0,0.12);
    border-width: 1px 1px 1px 1px;
    border-style: solid;
}
.grid-scroll-wrapper .k-grid {
    border-width: 0 !important;
}
.grid-scroll-wrapper .k-grid table {
    table-layout: fixed;
}
.task-bottom.k-grid {
    border-top-width: 0;
}

.password-validation {
    margin-top: 5px;
    font-size: 12px;
    list-style: none;
    padding-left: 5px;
}
.password-validation li {
    transition: color 500ms ease ;
}

.password-validation .valid {
    color: #73AF55;
}

.password-validation .invalid {
    color: #D06079;
}

.password-validation svg {
    width: 16px;
    display: inline-block;
    margin: 1px 3px 3px 1px;
  }
  
  .password-validation .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;    
  }

  .password-validation .path.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
  }
  .password-validation .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
  }
  .password-validation .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }
  
  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
  
  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  .ro-label {
    font-size: 13px;
    font-weight: bold;
    color: rgba(0,0,0,0.8);
    left: 11px;
    margin-bottom: 0px
  }

  .k-radio {
    padding: 10px !important;
}

.k-button.k-upload-button span {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
}
.taskSummary .k-grid-toolbar {
    padding: 4px !important;
}

div.k-grouping-header {
    display:none;
}

.activity-block-grid .k-grouping-row .k-icon {
    margin-left: 2px;
    margin-right: 14px;
}
#dialog-root .k-window .k-window-content {
    padding: 0px 16px;
}

.k-datepicker .k-picker-wrap .k-select {
    border-radius: 0;
}

.k-timepicker .k-picker-wrap {
    border: none;
}

.k-timepicker .k-picker-wrap .k-select {
    border-width: 1px 1px 1px 0;
    border-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.k-switch-label-on, .k-switch-label-off {
    display: block !important;
    padding: 0 10px;
}

.align-cells-top .k-grid-content table td {
    vertical-align: top;
}

.cell-line-height-1 .k-grid-content table td, .cell-line-height-1 .k-grid-content table td * {
    line-height: 1;
}

.cell-line-height-1 .k-grid-content table td * {
    margin: 0;
}

.Card-card-156 {
    font-size:12px !important;
}

.color-based-task.font-weight-bold div p {
    color: #ef803c;
}

.color-based-conversation-bold, .color-based-task-bold {
    color: #ef803c;
    font-weight: bold;
}